import React, { useEffect, useState } from 'react';
import DeviceDataService from '../../services/DeviceService';
import Badge from '@material-ui/core/Badge';
import MaterialTable from 'material-table';
import { API_URL } from '../../utils/setApiUrl.js';
import axios from 'axios';
import { Snackbar, TextField, Autocomplete } from '@mui/material';
import Alert from '@material-ui/lab/Alert';
import LocationService from '../../services/LocationService';
import useCheckPermission from '../../utils/permissions/useCheckPermissions';

const DevicesTable = () => {
    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    const isSuperUser = useCheckPermission('super');

    const client = axios.create({
        baseURL: API_URL,
    });

    const [devices, setDevice] = useState([]);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        // Call the function on component mount
        retrieveDevices();
        // Add locations fetch
        fetchLocations();

        // Set up an interval to call the function every 60 seconds (60000 milliseconds)
        const interval = setInterval(retrieveDevices, 60000);

        // Clear the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    const retrieveDevices = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await DeviceDataService.getAll(joinedValues);
            } else if (locData.id === 'all') {
                response = await DeviceDataService.adminAll();
            }

            if (response) {
                setDevice(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Add function to fetch locations
    const fetchLocations = async () => {
        try {
            const response = await LocationService.getAll();
            setLocations(response.data);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    // Add function to handle location update
    const handleLocationUpdate = async (newData, oldData) => {
        try {
            console.log('Updating device:', newData); // Debug log
            console.log('New location:', newData.DeviceLocation); // Debug log
            await DeviceDataService.update(newData.mac, {
                location: parseInt(newData.DeviceLocation.number),
            });
            handleShowSnackbar('Локацията е успешно променена!', 'success');
            retrieveDevices(); // Refresh the table
        } catch (error) {
            console.error('Update error:', error);
            handleShowSnackbar('Грешка при промяна на локацията!', 'error');
            throw error;
        }
    };

    // Snackbars Start
    const [snackbars, setSnackbars] = useState([]);

    const handleCloseSnackbar = id => {
        setSnackbars(prevSnackbars =>
            prevSnackbars.filter(snackbar => snackbar.id !== id)
        );
    };

    const handleShowSnackbar = (message, severity) => {
        const id = new Date().getTime(); // Unique ID for each snackbar
        const newSnackbar = {
            id,
            message,
            severity,
            position: snackbars.length * 55,
        };

        setSnackbars(prevSnackbars => [...prevSnackbars, newSnackbar]);
    };
    //Snackbars End

    // * Functionality for restarting devices START
    const restartDevice = async rowData => {
        const { name, mac } = rowData;

        try {
            const res = await client.post('/restartDevice', { mac });

            if (res.status === 200) {
                handleShowSnackbar(
                    `Успешно изпратихте команда за рестартиране към устройството!`,
                    'success'
                );
            }
        } catch (err) {
            handleShowSnackbar(
                `Командата не беше изпратена, моля опитайте отново!`,
                'error'
            );
        }
    };
    // * Functionality for restarting devices END

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const handleNameUpdate = async (newData, oldData) => {
        try {
            console.log('Updating device name:', newData);
            await DeviceDataService.update(newData.mac, {
                name: newData.name,
            });
            handleShowSnackbar('Името е успешно променено!', 'success');
            retrieveDevices();
        } catch (error) {
            console.error('Name update error:', error);
            handleShowSnackbar('Грешка при промяна на името!', 'error');
            throw error;
        }
    };

    const handleUpdate = async (newData, oldData) => {
        try {
            const updates = {};

            // Check if location was changed
            const newLocationNumber =
                newData.DeviceLocation && newData.DeviceLocation.number;
            const oldLocationNumber =
                oldData.DeviceLocation && oldData.DeviceLocation.number;
            if (newLocationNumber !== oldLocationNumber) {
                updates.location = parseInt(newData.DeviceLocation.number);
            }

            // Check if name was changed
            if (newData.name !== oldData.name) {
                updates.name = newData.name;
            }

            // Check if MAC was changed (for super users)
            if (isSuperUser && newData.mac !== oldData.mac) {
                updates.mac = newData.mac;
            }

            if (Object.keys(updates).length > 0) {
                // If MAC is being updated, use the old MAC as identifier
                const identifierMac = updates.mac ? oldData.mac : newData.mac;
                await DeviceDataService.update(identifierMac, updates);
                handleShowSnackbar('Промените са успешно запазени!', 'success');
                retrieveDevices();
            }
        } catch (error) {
            console.error('Update error:', error);
            handleShowSnackbar('Грешка при запазване на промените!', 'error');
            throw error;
        }
    };

    const columns = [
        {
            title: 'Устройство',
            field: 'name',
            editComponent: props => (
                <TextField
                    value={props.value || ''}
                    onChange={e => props.onChange(e.target.value)}
                    variant="standard"
                    size="small"
                    fullWidth
                    sx={{
                        '& .MuiInput-underline:after': {
                            borderBottomColor: '#003d4d',
                        },
                    }}
                />
            ),
            render: rowData => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Badge
                        color={rowData.online ? 'secondary' : 'error'}
                        variant="dot"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    />
                    <span style={{ marginLeft: '15px' }}>{rowData.name}</span>
                </div>
            ),
        },
        {
            title: 'Локация',
            field: 'DeviceLocation.name',
            editComponent: props => (
                <Autocomplete
                    options={locations}
                    getOptionLabel={option => option.name || ''}
                    value={
                        locations.find(
                            loc =>
                                loc.number ===
                                parseInt(
                                    props.rowData.DeviceLocation &&
                                        props.rowData.DeviceLocation.number
                                )
                        ) || null
                    }
                    onChange={(event, newValue) => {
                        console.log('Selected location:', newValue);
                        const updatedLocation = newValue
                            ? {
                                  id: newValue.id,
                                  number: newValue.number,
                                  name: newValue.name,
                              }
                            : null;
                        props.onChange(updatedLocation);
                        props.onRowDataChange({
                            ...props.rowData,
                            DeviceLocation: updatedLocation,
                        });
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            placeholder="Select location"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#003d4d',
                                    },
                                },
                            }}
                        />
                    )}
                    sx={{
                        minWidth: 200,
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#003d4d',
                            },
                        },
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.number === value.number
                    }
                    blurOnSelect
                    openOnFocus
                    selectOnFocus
                    clearOnBlur={false}
                    handleHomeEndKeys
                />
            ),
        },
        ...(isSuperUser
            ? [
                  {
                      title: 'MAC',
                      field: 'mac',
                      editComponent: props => (
                          <TextField
                              value={props.value || ''}
                              onChange={e => props.onChange(e.target.value)}
                              variant="standard"
                              size="small"
                              fullWidth
                              sx={{
                                  '& .MuiInput-underline:after': {
                                      borderBottomColor: '#003d4d',
                                  },
                              }}
                          />
                      ),
                  },
              ]
            : []),
    ];
    return (
        <div>
            {snackbars
                .slice()
                .reverse()
                .map((snackbar, index) => (
                    <Snackbar
                        key={snackbar.id}
                        open={true}
                        autoHideDuration={5000}
                        onClose={() => handleCloseSnackbar(snackbar.id)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        style={{ bottom: `${10 + index * 55}px` }}
                    >
                        <Alert
                            elevation={6}
                            variant="filled"
                            severity={snackbar.severity}
                            onClose={() => handleCloseSnackbar(snackbar.id)}
                        >
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                ))}
            <MaterialTable
                title="Устройства"
                data={devices}
                columns={columns}
                style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Опресни',
                        isFreeAction: true,
                        onClick: retrieveDevices,
                    },
                    {
                        icon: 'cached',
                        tooltip: 'Рестарт',
                        onClick: (event, rowData) => restartDevice(rowData),
                    },
                ]}
                options={{
                    loadingType: 'linear',
                    pageSize: 100,
                    maxBodyHeight: '65vh',
                    showTitle: true,
                    sorting: true,
                    actionsColumnIndex: -1,
                    exportButton: { csv: true, pdf: false },
                    filtering: true,
                    pageSizeOptions: [20, 50, 100, 500, 1000],
                    whiteSpace: 'nowrap',
                    rowStyle: {
                        overflowWrap: 'break-word',
                    },
                    search: false,
                    columnResizable: false,
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            handleUpdate(newData, oldData)
                                .then(() => resolve())
                                .catch(() => reject());
                        }),
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}',
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Loading data...',
                        filterRow: {
                            filterTooltip: 'Filter',
                        },
                        editRow: {
                            deleteText:
                                'Сигурни ли сте че искате да изтриете устройството?',
                        },
                    },
                }}
            />
        </div>
    );
};

export default DevicesTable;
