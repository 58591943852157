import React, { useEffect, useState } from 'react';
import MaterialTable, {
    MTableBody,
    MTableFilterRow,
    MTableToolbar,
} from 'material-table';
import LocationDataService from '../../../services/LocationService';
import axios from 'axios';
import { API_URL } from '../../../utils/setApiUrl.js';
import 'date-fns';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import { Settings } from 'luxon';
import DescriptionIcon from '@material-ui/icons/Description';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';
import dayjs from 'dayjs';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/bg';

Settings.defaultZone = 'Asia/Istanbul';

const SPACED_DATE_FORMAT = 'DD/MMM/YYYY H:MM:ss';

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    grid: {
        marginLeft: theme.spacing(2),
    },
    toolbar: { marginLeft: theme.spacing(10) },
    absoluteContainer: {
        position: 'absolute',
        zIndex: 9,
        width: '45vw',
        [theme.breakpoints.down('md')]: {
            position: 'initial',
        },
    },
}));

const DiscrepanciesTable = () => {
    const classes = useStyles();

    // Get user role from localStorage as a simple string
    const [isAnalyzer, setIsAnalyzer] = useState(false);

    // Initialize user role from localStorage when component mounts
    useEffect(() => {
        const userRole = localStorage.getItem('level');
        // Check if the role is 'analyzer'
        if (userRole) {
            setIsAnalyzer(userRole === 'analyzer');
        }
    }, []);

    const locData = JSON.parse(localStorage.getItem('loc'));

    const client = axios.create({
        baseURL: API_URL,
    });

    // ! Gap Filter Start
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        retrieveLocations();
    }, []);

    const retrieveLocations = () => {
        LocationDataService.getAll()
            .then(response => {
                setLocations(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const [locationValue, setLocationValue] = React.useState('');
    const [locationInputValue, setLocationInputValue] = React.useState('');

    var x = new Date();
    const [selectedStartDate, setStartDateChange] = useState(null);
    const [selectedEndDate, setEndDateChange] = useState(null);

    const handleEndDateChange = date => {
        setEndDateChange(date);
    };

    const handleStartDateChange = date => {
        // Format the date to 'YYYY-MM-DD'
        const formattedDate = date.format('YYYY-MM-DD');

        // Update the state with the formatted date
        setStartDateChange(formattedDate);
    };

    const [filteredDay, setFilteredDay] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const retrieveFilteredDay = async () => {
        setIsLoading(true); // Start loading

        setFilteredDay([]);

        // Format the start date as a local date string
        const startDate = selectedStartDate;

        let endDate = new Date(selectedEndDate);
        endDate.setDate(endDate.getDate() + 1);

        // Format the end date as a local date string
        const newEndDate = endDate.toISOString().split('T')[0];

        if (Array.isArray(locData)) {
            const joinedValues = locData.join(',');
            const request = client.get(
                `/discrepancies/date/${startDate}/${newEndDate}/loc/${joinedValues}`
            );
            try {
                const response = await request;
                if (response && response.data) {
                    // Ensure response has the data you need
                    setFilteredDay(response.data); // Assuming the relevant data is directly in response.data
                    setDataFetched(true);
                } else {
                    throw new Error('No data found in response');
                }
                setOpen(false);
            } catch (error) {
                setIserror(error.response);
                setErrorMessages('Не бяха намарени отклонения!'); // You can handle the JSX part where you render this message
                console.error(error.response);
            } finally {
                setIsLoading(false); // End loading regardless of success or failure
            }
        } else if (locData.id === 'all') {
            const request = client.get(
                `/discrepancies/date/${startDate}/${newEndDate}/all`
            );
            try {
                const response = await request;
                if (response && response.data) {
                    // Ensure response has the data you need
                    setFilteredDay(response.data); // Assuming the relevant data is directly in response.data
                    setDataFetched(true);
                } else {
                    throw new Error('No data found in response');
                }
                setOpen(false);
            } catch (error) {
                setIserror(error.response);
                setErrorMessages('Не бяха намарени отклонения!'); // You can handle the JSX part where you render this message
                console.error(error.response);
            } finally {
                setIsLoading(false); // End loading regardless of success or failure
            }
        }
    };

    // const retrieveFilteredDay = async () => {
    //     setFilteredDay([]);
    //
    //     setLoading(true);
    //
    //     const getDaysArray = (start, end) => {
    //         const dates = [];
    //         let currentDate = new Date(start);
    //         while (currentDate <= new Date(end)) {
    //             dates.push(new Date(currentDate));
    //             currentDate.setDate(currentDate.getDate() + 1);
    //         }
    //         return dates;
    //     };
    //
    //     const daylist = getDaysArray(selectedStartDate, selectedEndDate);
    //     setDates(daylist);
    //
    //     const requests = daylist.map((d) => {
    //         return client.get(
    //             `/discrepancies/date/${JSON.stringify(d)
    //                 .split('T')[0]
    //                 .replace(/\"/g, '')}`
    //         );
    //     });
    //
    //     try {
    //         const responses = await Promise.all(requests);
    //         const allData = responses.flatMap((response) => response.data);
    //         setFilteredDay(allData);
    //         setDataFetched(true);
    //         setOpen(false);
    //     } catch (error) {
    //         setIserror(error.response);
    //         setErrorMessages(
    //             <Alert severity="error">Не бяха намарени отклонения!</Alert>
    //         );
    //         console.log(error.response);
    //     } finally {
    //         setLoading(false); // Set loading to false after data has been fetched (both on success and error)
    //     }
    // };

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    // * Add Filters to State Start
    const [selectedRows, setSelectedRows] = useState([]);

    // const [components] = useState({
    // 	Toolbar = props => <MTableToolbar {...props} />,
    // })

    const [columns] = useState([
        {
            title: '# на Служител',
            field: 'user_id',
            editable: 'never',
        },
        {
            title: 'Локация',
            field: 'location_name',
            editable: 'never',
        },
        {
            title: 'Отдел',
            field: 'sub_loc_name',
            editable: 'never',
        },
        {
            title: 'Име',
            field: 'user_name',
            editable: 'never',
            defaultSort: 'ASC',
        },
        {
            title: 'Проблем',
            field: 'problem',
            editable: 'never',
        },
        {
            title: 'Първи Вход',
            field: 'first_entry_time',
            filtering: false,
            editable: 'never',
            type: 'datetime',
            dateSetting: { locale: 'bg-BG', hour12: false },
        },
        {
            title: 'Локация на Вход',
            field: 'enter_location_name',
            editable: 'never',
        },
        {
            title: 'Устройство на Вход',
            field: 'enter_device_name',
            editable: 'never',
        },
        // {
        //     title: 'Последен Изход',
        //     filtering: false,
        //     field: 'last_exit_time',
        //     editable: 'never',
        //     type: 'datetime',
        //     dateSetting: { locale: 'bg-BG', hour12: false },
        // },
        // {
        //     title: 'Локация на Изход',
        //     field: 'exit_location_name',
        //     editable: 'never',
        // },
        // {
        //     title: 'Устройство на Изход',
        //     field: 'exit_device_name',
        //     editable: 'never',
        // },
    ]);
    // * Add Filters to State End

    const [components] = useState({
        Toolbar: props => (
            <div>
                <MTableToolbar {...props} />
            </div>
        ),
        FilterRow: props => <MTableFilterRow {...props} />,
    });

    // ! Format the Date and time columns for export
    const formatDate = inputTime => {
        if (inputTime && inputTime !== 'null' && inputTime !== 'empty') {
            const date = new Date(inputTime);
            return `${date
                .getDate()
                .toString()
                .padStart(2, '0')}.${(date.getMonth() + 1)
                .toString()
                .padStart(2, '0')}.${date.getFullYear()} г., ${date
                .getHours()
                .toString()
                .padStart(2, '0')}:${date
                .getMinutes()
                .toString()
                .padStart(2, '0')}:${date
                .getSeconds()
                .toString()
                .padStart(2, '0')} ч.`;
        }
        return 'empty';
    };

    // ! Header mapping for exported column titles
    const headerMapping = {};
    columns.forEach(col => {
        headerMapping[col.field] = col.title;
    });

    const mapHeaders = row => {
        let newRow = {};
        for (let key in row) {
            if (headerMapping[key]) {
                newRow[headerMapping[key]] = row[key];
            } else {
                newRow[key] = row[key];
            }
        }
        return newRow;
    };

    // ! Format the name for the file
    const formatDateForFilename = inputDate => {
        const date = new Date(inputDate);
        return `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date
            .getDate()
            .toString()
            .padStart(2, '0')}`;
    };

    const formattedStartDate = formatDateForFilename(selectedStartDate);
    const formattedEndDate = formatDateForFilename(selectedEndDate);

    // ! Export to XLS Function
    const tableRef = React.createRef();
    const exportTable = () => {
        const currentTableData = tableRef.current.dataManager.filteredData;

        // Transform the data
        const transformedData = currentTableData.map(row => {
            const hours = row.worked_hours || 0; // Use 0 if worked_hours is null or undefined
            const mins = row.mins || 0; // Use 0 if mins is null or undefined

            // Check for << and >> conditions for first_entry_time and last_exit_time
            const firstTimeValue =
                row.first_entry_time === '<<'
                    ? 'липсва вход'
                    : formatDate(row.first_entry_time);
            const lastTimeValue =
                row.last_exit_time === '>>'
                    ? 'липсва изход'
                    : formatDate(row.last_exit_time);

            return {
                ...row,
                time: formatDate(row.time),
                first_entry_time: firstTimeValue,
                last_exit_time: lastTimeValue,
                worked_hours: `${hours} часа и ${mins} минути`,
            };
        });

        // Only include displayed columns in the export
        const exportedData = transformedData.map(row => {
            let newRow = {};
            columns.forEach(col => {
                newRow[col.field] = row[col.field];
            });
            return mapHeaders(newRow);
        });

        // Export the data
        const ws = XLSX.utils.json_to_sheet(exportedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const buf = XLSX.write(wb, { bookType: 'xls', type: 'array' });
        saveAs(
            new Blob([buf], { type: 'application/vnd.ms-excel' }),
            `Discrepancies_${formattedStartDate} - ${formattedEndDate}.xls`
        );
    };

    return (
        <div>
            <Paper>
                <Grid
                    container
                    spacing={1}
                    className={classes.absoluteContainer}
                    style={{ paddingLeft: '16px' }}
                >
                    <Grid item xs={4} lg={3} style={{ paddingTop: '20px' }}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="bg"
                        >
                            <MobileDatePicker
                                margin="normal"
                                closeOnSelect={true}
                                id="selectedStartDate"
                                label="Начална Дата"
                                defaultValue={dayjs()}
                                ampm={false}
                                value={selectedStartDate}
                                onChange={date => handleStartDateChange(date)}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4} lg={3} style={{ paddingTop: '20px' }}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="bg"
                        >
                            <MobileDatePicker
                                margin="normal"
                                closeOnSelect={true}
                                id="selectedEndDate"
                                label="Крайна Дата"
                                defaultValue={dayjs()}
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4} lg={6}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={retrieveFilteredDay}
                            disabled={isLoading} // Disable button when loading
                        >
                            {isLoading ? 'Зареждаме...' : 'Филтрирай'}
                        </Button>
                    </Grid>
                </Grid>
                <MaterialTable
                    title=""
                    columns={[
                        {
                            title: '# на Служител',
                            field: 'user_id',
                            editable: 'never',
                        },
                        {
                            title: 'Локация на Устройство',
                            field: 'location_name',
                            editable: 'never',
                        },
                        {
                            title: 'Отдел',
                            field: 'sub_loc_name',
                            editable: 'never',
                        },
                        {
                            title: 'Име',
                            field: 'user_name',
                            editable: 'never',
                        },
                        {
                            title: 'Проблем',
                            field: 'problem',
                            editable: 'never',
                        },
                        {
                            title: 'Първи Вход',
                            field: 'first_entry_time',
                            filtering: false,
                            editable: 'never',
                            type: 'datetime',
                            dateSetting: { locale: 'bg-BG', hour12: false },
                        },
                        // {
                        //     title: 'Локация на Вход',
                        //     field: 'enter_location_name',
                        //     editable: 'never',
                        // },
                        {
                            title: 'Устройство на Вход',
                            field: 'enter_device_name',
                            editable: 'never',
                        },
                        // {
                        //     title: 'Последен Изход',
                        //     filtering: false,
                        //     field: 'last_exit_time',
                        //     editable: 'never',
                        //     type: 'datetime',
                        //     dateSetting: { locale: 'bg-BG', hour12: false },
                        // },
                        // {
                        //     title: 'Локация на Изход',
                        //     field: 'exit_location_name',
                        //     editable: 'never',
                        // },
                        // {
                        //     title: 'Устройство на Изход',
                        //     field: 'exit_device_name',
                        //     editable: 'never',
                        // },
                    ]}
                    data={filteredDay || iserror}
                    tableRef={tableRef}
                    style={{
                        borderRadius: '0',
                        boxShadow: 'none',
                    }}
                    // actions={[
                    // 	{
                    // 		icon: "refresh",
                    // 		tooltip: "Refresh",
                    // 		isFreeAction: true,
                    // 		onClick: retrieveEvents,
                    // 	},
                    // ]}
                    components={{
                        components,
                        Body: props =>
                            !dataFetched ? (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        margin: '15% auto',
                                        position: 'absolute',
                                        textAlign: 'center',
                                    }}
                                >
                                    "Изберете период за да видите
                                    Разминаванията!"
                                </div>
                            ) : (
                                <MTableBody {...props} />
                            ),
                    }}
                    actions={[
                        ...(!isAnalyzer
                            ? [
                                  {
                                      icon: () => <DescriptionIcon />,
                                      tooltip: 'Export to Excel',
                                      isFreeAction: true,
                                      onClick: exportTable,
                                  },
                              ]
                            : []),
                    ]}
                    options={{
                        loadingType: 'linear',
                        pageSize: 100,
                        minBodyHeight: '67vh',
                        maxBodyHeight: '67vh',
                        showTitle: true,
                        sorting: true,
                        actionsColumnIndex: -1,
                        exportButton: !isAnalyzer
                            ? { csv: true, pdf: false }
                            : false,
                        filtering: true,
                        pageSizeOptions: [20, 50, 100, 200, 300, 400, 500],
                        search: false,
                        toolbar: true,
                        // rowStyle: rowData => {
                        // 	if (rowData.problem === "<<") {
                        // 		return "Няма изход!"
                        // 	} else if (rowData.problem === ">>") {
                        // 		return "Няма вход!"
                        // 	}
                        // },
                    }}
                    // editable={{
                    // 	onRowUpdate: oldEvent =>
                    // 		new Promise(resolve => {
                    // 			handleEventUpdate(oldEvent, resolve)
                    // 			retrieveEvents()
                    // 		}),
                    // 	onRowDelete: Event =>
                    // 		new Promise(resolve => {
                    // 			handleEventRemove(Event, resolve)
                    // 			retrieveEvents()
                    // 		}),
                    // }}
                    onSelectionChange={rows => {
                        setSelectedRows(rows);
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} от {count}',
                            labelRowsSelect: 'Събития',
                        },
                        header: {
                            actions: 'Действия',
                        },
                        body: {
                            emptyDataSourceMessage: 'Няма разминавания!',
                            editTooltip: 'Редактирай',
                            deleteTooltip: 'Премахни',
                            filterRow: {
                                filterTooltip: 'Филтър',
                            },
                            editRow: {
                                deleteText:
                                    'Сигурни ли сте че искате да изтриете събитието?',
                                cancelTooltip: 'Отказ',
                                saveTooltip: 'Запази',
                            },
                        },
                        toolbar: {
                            exportTitle: 'Експорт',
                            exportName: 'Експорт към CSV',
                            searchTooltip: 'Tърси',
                            searchPlaceholder: 'Tърси',
                            nRowsSelected: '{0} реда избрани',
                        },
                    }}
                />
            </Paper>
        </div>
    );
};

export default DiscrepanciesTable;
