import React, { useEffect, useState } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    CssBaseline,
    FormControl,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import AppBarComponent from '../../layout/AppBar';
import Hands from './hands.png';
import EnrollGif from './enroll.gif';
import Scrollbar from 'react-scrollbars-custom';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Alert from '@material-ui/lab/Alert';
import { useForm } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { API_URL } from '../../../utils/setApiUrl.js';
import UsersDataService from '../../../services/UserService';
import UserDataService from '../../../services/UserService';
import DevicesDataService from '../../../services/DeviceService';
import DeviceDataService from '../../../services/DeviceService';
import DeviceUsersService from '../../../services/DeviceUsersService';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import { Snackbar } from '@mui/material';

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        backgroundColor: 'white',
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        background: 'linear-gradient(#006987 0%, #003544 100%)',
        border: 'none',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    card: {
        borderRadius: '8px',
    },
    logo: {
        '& .logo-icon': {
            width: '170px',
            margin: theme.spacing(2.5, 6),
            transition: theme.transitions.create(['width'], {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.easeInOut,
            }),
        },
    },
    item: props => ({
        height: 40,
        /* width: 'calc(100% - 16px)', */
        borderRadius: '0',
        /* paddingRight: 12, */
        paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
        '&.active': {
            backgroundColor: '#8adc39',
            color: `${theme.palette.secondary.contrastText}!important`,
            pointerEvents: 'none',
            transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
            '& .list-item-text-primary': {
                color: 'inherit',
            },
            '& .list-item-icon': {
                color: theme.palette.text.icon,
            },
        },
        '& .list-item-icon': {
            marginRight: 16,
            color: theme.palette.text.icon,
        },
        '& .list-item-text': {},
        color: theme.palette.text.icon,
        cursor: 'pointer',
        textDecoration: 'none!important',
    }),
    itemtext: {
        fontSize: '0.8rem',
    },
    icon: {
        color: 'white',
    },
    nested: {
        fontSize: '0.9rem',
        height: 40,
        paddingLeft: theme.spacing(9),
    },
    fingers: {
        borderRadius: '8px',
        height: '400px',
        backgroundImage: `url(${Hands})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '90% auto',
    },
    radioGroup: {
        float: 'right',
        position: 'absolute',
        width: '55rem',
        display: 'inline-block',
        left: '16.7rem',
        marginTop: '-1rem',
    },
    radioGroupLeft: {
        width: '55rem',
        display: 'inline-block',
        left: '17rem',
        marginTop: '-2.3rem',
    },
    '@global': {
        '.MuiInputLabel-outlined.MuiInputLabel-marginDense': {
            marginLeft: '0 !important',
        },
        'div > label': {
            marginLeft: '5px !important',
            marginRight: '5px !important',
        },
        'div > label:nth-of-type(1)': {
            marginLeft: '1.3rem !important',
            marginBottom: '-21rem',
            [theme.breakpoints.down('md')]: {
                marginLeft: '-8rem !important',
                marginBottom: '-21rem',
            },
        },
        'div > label:nth-of-type(2)': {
            marginLeft: '-0.7rem !important',
            marginBottom: '-7rem',
            [theme.breakpoints.down('md')]: {
                marginLeft: '-1.6rem !important',
                marginBottom: '-13rem',
            },
        },
        '.MuiIconButton-root.Mui-disabled': {
            color: '#f006',
        },
        '.MuiFormControlLabel-label.Mui-disabled': {
            color: '#f006',
        },
        'div > label:nth-of-type(3)': {
            marginLeft: '1.2rem !important',
            marginBottom: '-3rem',
            [theme.breakpoints.down('md')]: {
                marginLeft: '-0.7rem !important',
                marginBottom: '-11rem',
            },
        },
        'div > label:nth-of-type(4)': {
            marginLeft: '2rem !important',
            marginBottom: '-6rem',
            [theme.breakpoints.down('md')]: {
                marginLeft: '-0.3rem !important',
                marginBottom: '-12rem',
            },
        },
        'div > label:nth-of-type(5)': {
            marginLeft: '-0.2rem !important',
            marginBottom: '-12rem',
            [theme.breakpoints.down('md')]: {
                marginLeft: '-1.2rem !important',
                marginBottom: '-16rem',
            },
        },
        'fieldset > div > label:nth-of-type(5)': {
            marginLeft: '-0.5rem !important',
            marginBottom: '-21rem',
            [theme.breakpoints.down('md')]: {
                marginLeft: '-1.5rem !important',
                marginBottom: '-21rem',
            },
        },
        'fieldset > div > label:nth-of-type(4)': {
            marginLeft: '1.5rem !important',
            marginBottom: '-7rem',
            [theme.breakpoints.down('md')]: {
                marginLeft: '-0.6rem !important',
                marginBottom: '-13rem',
            },
        },
        'fieldset > div > label:nth-of-type(3)': {
            marginLeft: '1.5rem !important',
            marginBottom: '-3rem',
            [theme.breakpoints.down('md')]: {
                marginLeft: '-0.5rem !important',
                marginBottom: '-11rem',
            },
        },
        'fieldset > div > label:nth-of-type(2)': {
            marginLeft: '0rem !important',
            marginBottom: '-6rem',
            [theme.breakpoints.down('md')]: {
                marginLeft: '-1.2rem !important',
                marginBottom: '-12.5rem',
            },
        },
        'fieldset > div > label:nth-of-type(1)': {
            marginLeft: '0.5rem !important',
            marginBottom: '-12rem',
            [theme.breakpoints.down('md')]: {
                marginLeft: '-1.2rem !important',
                marginBottom: '-16rem',
            },
        },
        'legend > div > label:nth-of-type(1)': {
            marginLeft: '0 !important',
        },
    },
    textField: {
        margin: theme.spacing(0),
        minWidth: '270px',
        maxWidth: '15vw',
    },
    textField1: {
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(-5px, -6px) scale(0.75)',
            marginLeft: '0 !important',
            [theme.breakpoints.down('md')]: {
                transform: 'translate(165%, -6px) scale(0.75)',
            },
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(0px, 12px) scale(1)',
            marginLeft: '0 !important',
            [theme.breakpoints.down('md')]: {
                transform: 'translate(0, 12px) scale(1)',
            },
        },
    },
    textField2: {
        '& .MuiInputLabel-outlined': {
            transform: 'translate(0px, 12px) scale(1)',
            [theme.breakpoints.down('md')]: {
                transform: 'translate(0, 12px) scale(1)',
            },
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(-5px, -6px) scale(0.75)',
            [theme.breakpoints.down('md')]: {
                transform: 'translate(145%, -6px) scale(0.75)',
            },
        },
    },
    legend: {
        flexDirection: 'row',
        width: '97%',
        margin: '10px !important',
        marginTop: '15px !important',
        marginBottom: '0 !important',
    },
    legend2: {
        flexDirection: 'row',
        width: '93%',
        margin: '10px !important',
        marginTop: '15px !important',
        marginBottom: '0 !important',
    },
    enrollFields: {
        width: '100% !important',
        '& .MuiInputLabel-outlined': {
            transform: 'translate(0, 12px) scale(1)',
            [theme.breakpoints.down('md')]: {
                transform: '0',
            },
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(-5px, -6px) scale(0.75)',
            [theme.breakpoints.down('md')]: {
                transform: '0',
            },
        },
    },
    cardContent: {
        padding: '8px !important',
        '& .mui-validate__validate-root': {
            width: '95% !important',
        },
    },
}));

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function Devices(props) {
    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    const [isLoading, setIsLoading] = useState(false);

    // * Users Dropdown Start
    const [users, setUsers] = useState([]);

    useEffect(() => {
        retrieveUsers();
    }, []);

    const retrieveUsers = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await UserDataService.getAll(joinedValues);
            } else if (locData.id === 'all') {
                response = await UserDataService.adminAll();
            }

            if (response) {
                setUsers(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // * Devices Dropdown
    const [devices, setDevices] = useState([]);
    const [enrollers, setEnrollers] = useState([]);

    useEffect(() => {
        retrieveDevices();
    }, []);

    const retrieveDevices = async () => {
        if (hostname === 'billa.biodit.bg') {
            DevicesDataService.adminAll()
                .then(response => {
                    setDevices(response.data);
                })
                .catch(e => {
                    setErrorMessages(['Cannot load devices']);
                    setIserror(true);
                });
        } else {
            const locData = JSON.parse(localStorage.getItem('loc'));

            try {
                let response;

                if (Array.isArray(locData)) {
                    const joinedValues = locData.join(',');
                    response = await DeviceDataService.getAll(joinedValues);
                } else if (locData.id === 'all') {
                    response = await DeviceDataService.adminAll();
                }

                if (response) {
                    setDevices(response.data);
                    console.log(response.data);
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    // * Form Radios
    const [value, setValue] = useState('');
    const handleChange = event => {
        const selectedValue = event.target.value;

        // Enable the selected radio button
        setDisabled({ ...disabled, [selectedValue]: false });
        setValue(selectedValue);
    };

    // Snackbars Start
    const [snackbars, setSnackbars] = useState([]);

    const handleCloseSnackbar = id => {
        setSnackbars(prevSnackbars =>
            prevSnackbars.filter(snackbar => snackbar.id !== id)
        );
    };

    const handleShowSnackbar = (message, severity) => {
        const id = new Date().getTime(); // Unique ID for each snackbar
        const newSnackbar = {
            id,
            message,
            severity,
            position: snackbars.length * 55,
        };

        setSnackbars(prevSnackbars => [...prevSnackbars, newSnackbar]);
    };
    //Snackbars End

    // ! Enroll Function Start
    const { handleSubmit, errors } = useForm();

    const [enrollMessage, setEnrollMessage] = useState('');
    const [enrollErrorMessage, setEnrollErrorMessage] = useState('');

    const [openModal, setOpenModal] = React.useState(false);

    async function enrollUser(res) {
        setOpenModal(true);

        try {
            const { data, status } = await axios.post(
                `${API_URL}/enroll`,
                {
                    user: userValue.sap,
                    finger: value,
                    device: enrollValue.mac,
                },
                {
                    validateStatus: () => true,
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            if (status === 200) {
                setOpenModal(false);
                handleShowSnackbar(
                    `Успешно добавихте пръстовия отпечатък!`,
                    'success'
                );
                retrieveUsers();
            } else if (status === 400) {
                setOpenModal(false);
                handleShowSnackbar(
                    `Пръстовият отпечатък не беше добавен, моля опитайте отново!`,
                    'error'
                );
            } else {
                setOpenModal(false);
                handleShowSnackbar(
                    `Възникна грешка. Моля опитайте отново!`,
                    'error'
                );
            }

            console.log(data);
        } catch (error) {
            setOpenModal(false);
            handleShowSnackbar(
                `Възникна грешка. Моля опитайте отново!`,
                'error'
            );
            console.log(error.response);
        }
    }

    // ! Enroll Function End

    // * Sidebar SubMenu Function
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    // ! Autocomplete for users Start
    const [userValue, setUserValue] = React.useState(users[0]);
    const [inputValue, setInputValue] = React.useState('');

    const [deviceValue, setDeviceValue] = React.useState(devices[0]);
    const [deviceInputValue, setDeviceInputValue] = React.useState('');

    const [enrollValue, setEnrollValue] = React.useState(enrollers[0]);
    const [enrollInputValue, setEnrollInputValue] = React.useState('');
    // ! Autocomplete for users End

    // * Allocate User Device + DB
    const [allocateMessage, setAllocationMessage] = useState('');
    const [allocateErrorMessage, setAllocationErrorMessage] = useState('');

    const [allMessage, setAllMessage] = useState('');

    const [userAllocateValue, setUserAllocateValue] = React.useState(users[0]);
    const [userInputValue, setUserInputValue] = React.useState('');

    const [deviceUsers, setDeviceUsers] = useState([]);

    const [deviceAllocateValue, setDeviceAllocateValue] = React.useState(
        devices[0]
    );
    const [
        deviceAllocateInputValue,
        setDeviceAllocateInputValue,
    ] = React.useState('');

    const hostname = window.location.hostname;

    async function allocateUser(res) {
        const client = axios.create({
            baseURL: API_URL,
        });

        setIsLoading(true);
        handleShowSnackbar(`Изпращане на служител... Моля, изчакайте!`, 'info');

        try {
            // Get the current host
            let deallocateQuery;

            if (hostname === 'billa.biodit.bg') {
                deallocateQuery = client.post('/removeUserEverywhere', {
                    user: JSON.stringify(userValue.sap),
                });
            } else {
                // Default deallocate query
                deallocateQuery = client.post(`/deallocate`, {
                    user: userValue.sap,
                    device: deviceValue.mac,
                });
            }

            let removeFromDevices;

            if (hostname === 'billa.biodit.bg') {
                removeFromDevices = DeviceUsersService.remove(userValue.sap);
            } else {
                // Default remove from device
                removeFromDevices = DeviceUsersService.removeFromDevice(
                    userValue.sap,
                    deviceValue.id
                );
            }

            const [
                templatesResponse,
                deallocateResponse,
                dbRemove,
            ] = await Promise.all([
                client.get(`/v1/templates/user/${userValue.sap}`),
                deallocateQuery,
                removeFromDevices,
            ]);
            const templates = templatesResponse.data;
            const deallocationStatus = deallocateResponse.status;
            const removedStatus = dbRemove.status;

            if (
                templates.length === 0 &&
                deallocationStatus === 200 &&
                removedStatus === 200
            ) {
                const freeSlotResponse = await client.post(
                    '/freeSlot',
                    { device: deviceValue.id },
                    { timeout: 5000 }
                );
                const slot = freeSlotResponse.data.message;
                await DeviceUsersService.create({
                    device_id: deviceValue.id,
                    user_id: userValue.sap,
                    slot: 0,
                    template_id: null,
                });

                setDeviceUsers();

                const insertResponse = await client.post('/insert', {
                    device: deviceValue.mac,
                    slot: 0,
                    user: userValue.sap,
                    pin: userValue.pin,
                    template: null,
                    card: userValue.card,
                });

                if (insertResponse.status === 200) {
                    setIsLoading(false);
                    handleShowSnackbar(
                        `Успешно изпратихте служителя към устройството!`,
                        'success'
                    );
                } else {
                    handleShowSnackbar(
                        `Служителя не беше изпратен към устройстово. Моля опитайте отново!`,
                        'error'
                    );
                }
            } else {
                async function addTemplatesWithDelay(templates) {
                    for (let i = 0; i < templates.length; i++) {
                        const item = templates[i];

                        const freeSlotResponse = await client.post(
                            '/freeSlot',
                            { device: deviceValue.id },
                            { timeout: 5000 }
                        );
                        const slot = freeSlotResponse.data.message;
                        await DeviceUsersService.create({
                            device_id: deviceValue.id,
                            user_id: userValue.sap,
                            slot,
                            template_id: item.id,
                        });

                        setDeviceUsers();

                        const insertResponse = await client.post('/insert', {
                            device: deviceValue.mac,
                            slot,
                            user: userValue.sap,
                            pin: userValue.pin,
                            template: item.template,
                            card: userValue.card,
                        });

                        if (insertResponse.status === 200) {
                            setIsLoading(false);
                            handleShowSnackbar(
                                `Успешно изпратихте служителя към устройството!`,
                                'success'
                            );
                        } else {
                            handleShowSnackbar(
                                `Служителя не беше изпратен към устройстово. Моля опитайте отново!`,
                                'error'
                            );
                        }

                        // Add delay of 5 seconds between each template request
                        if (i < templates.length - 1) {
                            await new Promise(resolve =>
                                setTimeout(resolve, 4000)
                            );
                        }
                    }
                }

                // Call the function with the templates array
                await addTemplatesWithDelay(templates);
            }
        } catch (error) {
            handleShowSnackbar(
                `Възникна грешка. Моля, опитайте отново!`,
                'error'
            );
            console.log(error);
        }
    }

    const [deallocateMessage, setDeallocationMessage] = useState('');
    const [deallocateErrorMessage, setDeallocationErrorMessage] = useState('');

    const [userDeallocateValue, setUserDeallocateValue] = React.useState(
        users[0]
    );
    const [inputDeallocateValue, setInputDeallocateValue] = React.useState('');

    const [deviceDeallocateValue, setDeviceDeallocateValue] = React.useState(
        devices[0]
    );
    const [
        deviceDeallocateInputValue,
        setDeviceInputDeallocateValue,
    ] = React.useState('');

    const deallocateUser = async () => {
        setIsLoading(true);

        handleShowSnackbar(`Изтриване на служител... Моля, изчакайте!`, 'info');

        try {
            const client = axios.create({ baseURL: API_URL });
            const { sap: user } = userDeallocateValue;
            const { mac: device } = deviceDeallocateValue;
            const { id: deviceId } = deviceDeallocateValue;

            const response = await client.post('/deallocate', {
                user: user,
                device,
            });
            const { status } = response;

            if (status === 200) {
                const { status } = await DeviceUsersService.removeFromDevice(
                    user,
                    deviceId
                );

                if (status === 200) {
                    setIsLoading(false);
                    handleShowSnackbar(
                        `Успешно премахнахте служителя от устройството!`,
                        'success'
                    );
                } else {
                    setIsLoading(false);
                    handleShowSnackbar(
                        `Служителя не беше премахнат. Моля опитайте отново!`,
                        'success'
                    );
                }
            }
        } catch (error) {
            handleShowSnackbar(
                `Възникна грешка. Моля, опитайте отново!`,
                'error'
            );
            console.error(error);
        }
    };

    const [disabled, setDisabled] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
    });

    const handleClear = () => {
        for (let i = 1; i <= 10; i++) {
            const setter = `setDisabled${i}`;
            eval(`${setter}(false);`);
        }
    };

    const handleSelect = value => {
        UsersDataService.get(value.sap)
            .then(response => {
                let newDisabledState = { ...disabled };
                if (response.data.enrolledFingers) {
                    // Handle array of enrolled fingers
                    response.data.enrolledFingers.forEach(finger => {
                        if (finger >= 1 && finger <= 10) {
                            newDisabledState[finger] = true;
                        }
                    });
                }
                setDisabled(newDisabledState);
            })
            .catch(e => {
                setErrorMessages(['Cannot load user data']);
                setIserror(true);
            });
    };

    const [allDevicesMacs, setAllDeviceMacs] = useState([]);
    const [allDevicesIds, setAllDeviceIds] = useState([]);

    async function sendToAll() {
        const client = axios.create({
            baseURL: API_URL,
        });

        setIsLoading(true);
        handleShowSnackbar(`Изпращане на служител... Моля, изчакайте!`, 'info');

        try {
            const response = await client.post('/removeUserEverywhere', {
                user: JSON.stringify(userValue.sap),
            });

            if (response.status === 200) {
                await DeviceUsersService.remove(userValue.sap);

                for (const device of devices) {
                    try {
                        const templateResponse = await client.get(
                            `/v1/templates/user/${userValue.sap}`
                        );
                        const templates = templateResponse.data;

                        if (templates.length === 0) {
                            try {
                                const slotResponse = await client.post(
                                    '/freeSlot',
                                    { device: device.id },
                                    { timeout: 5000 }
                                );
                                const slot = slotResponse.data.message;

                                await DeviceUsersService.create({
                                    device_id: device.id,
                                    user_id: userValue.sap,
                                    slot: slot,
                                    template_id: null,
                                });

                                await client.post('/insert', {
                                    device: device.mac,
                                    slot: slot,
                                    user: JSON.stringify(userValue.sap),
                                    pin: userValue.pin,
                                    template: null,
                                    card: userValue.card,
                                });
                            } catch (e) {
                                setIserror(true);
                            }
                        } else {
                            for (const [index, item] of templates.entries()) {
                                await new Promise(resolve =>
                                    setTimeout(resolve, 5000 * index)
                                );

                                try {
                                    const slotResponse = await client.post(
                                        '/freeSlot',
                                        { device: device.id },
                                        { timeout: 5000 }
                                    );
                                    const slot = slotResponse.data.message;

                                    await DeviceUsersService.create({
                                        device_id: device.id,
                                        user_id: userValue.sap,
                                        slot: slot,
                                        template_id: item.id,
                                    });

                                    await client.post('/insert', {
                                        device: device.mac,
                                        slot: slot,
                                        user: JSON.stringify(userValue.sap),
                                        pin: userValue.pin,
                                        template: item.template,
                                        card: userValue.card,
                                    });
                                } catch (e) {
                                    setIserror(true);
                                }
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }

                setDeviceUsers();

                setIsLoading(false);

                handleShowSnackbar(
                    `Успешно изпратихте служителя към всички устройства!`,
                    'success'
                );
            } else {
                handleShowSnackbar(
                    `Служителя не беше изпратен. Моля опитайте отново!`,
                    'error'
                );
            }
        } catch (error) {
            handleShowSnackbar(
                `Възникна грешка. Моля, опитайте отново!`,
                'error'
            );
        }
    }

    function removeFromEverywhere() {
        const client = axios.create({
            baseURL: API_URL,
        });

        client
            .post(`/removeUserEverywhere`, {
                user: JSON.stringify(userValue.sap),
            })
            .then(response => {
                if (response.status === 200) {
                    DeviceUsersService.remove(userValue.sap).then(response => {
                        if (response.status === 200) {
                            handleShowSnackbar(
                                `Успешно премахнахте служителя от всички устройства!`,
                                'success'
                            );
                        } else {
                            handleShowSnackbar(
                                `Служителя не беше премахнат, моля опитайте отново!`,
                                'error'
                            );
                        }
                    });
                } else {
                    handleShowSnackbar(
                        `Възникна грешка. Моля, опитайте отново!`,
                        'error'
                    );
                }
            });
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent />
            <main className={classes.content}>
                {snackbars
                    .slice()
                    .reverse()
                    .map((snackbar, index) => (
                        <Snackbar
                            key={snackbar.id}
                            open={true}
                            autoHideDuration={5000}
                            onClose={() => handleCloseSnackbar(snackbar.id)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            style={{ bottom: `${10 + index * 55}px` }}
                        >
                            <Alert
                                elevation={6}
                                variant="filled"
                                severity={snackbar.severity}
                                onClose={() => handleCloseSnackbar(snackbar.id)}
                            >
                                {snackbar.message}
                            </Alert>
                        </Snackbar>
                    ))}
                <Scrollbar
                    id="RSC-Example"
                    style={{ width: '100%', height: '100%' }}
                >
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <form
                                    onSubmit={handleSubmit(enrollUser)}
                                    name="form"
                                >
                                    <Card className={clsx(classes.card)}>
                                        {enrollMessage}
                                        {enrollErrorMessage}
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    component="legend"
                                                    className={classes.legend}
                                                >
                                                    <Autocomplete
                                                        autoSelect
                                                        value={users.sap}
                                                        onChange={(
                                                            event,
                                                            newUserValue,
                                                            reason
                                                        ) => {
                                                            setUserValue(
                                                                newUserValue
                                                            );
                                                            if (
                                                                reason ===
                                                                'clear'
                                                            ) {
                                                                handleClear();
                                                            } else {
                                                                handleSelect(
                                                                    newUserValue
                                                                );
                                                            }
                                                        }}
                                                        inputValue={inputValue}
                                                        onInputChange={(
                                                            event,
                                                            newInputValue
                                                        ) => {
                                                            setInputValue(
                                                                newInputValue
                                                            );
                                                        }}
                                                        id="auto-select user"
                                                        getOptionLabel={option =>
                                                            option.name +
                                                                ' - ' +
                                                                option.sap || ''
                                                        }
                                                        getOptionSelected={users =>
                                                            users.sap
                                                        }
                                                        options={users}
                                                        classesName={
                                                            classes.textField1
                                                        }
                                                        fullWidth={true}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Служител"
                                                                required
                                                                fullWidth
                                                                id="fullWidth"
                                                                variant="outlined"
                                                                size="small"
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    component="legend"
                                                    className={classes.legend2}
                                                >
                                                    <Autocomplete
                                                        autoSelect
                                                        value={devices.mac}
                                                        onChange={(
                                                            event,
                                                            newEnrollValue
                                                        ) => {
                                                            setEnrollValue(
                                                                newEnrollValue
                                                            );
                                                        }}
                                                        inputValue={
                                                            enrollInputValue
                                                        }
                                                        onInputChange={(
                                                            event,
                                                            enrollInputValue
                                                        ) => {
                                                            setEnrollInputValue(
                                                                enrollInputValue
                                                            );
                                                        }}
                                                        id="auto-select enroll"
                                                        getOptionLabel={devices =>
                                                            devices.name.toString()
                                                        }
                                                        getOptionSelected={devices =>
                                                            devices.mac
                                                        }
                                                        fullWidth={true}
                                                        options={devices}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label="Устройство"
                                                                variant="outlined"
                                                                fullWidth
                                                                id="fullWidth"
                                                                size="small"
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <CardContent
                                            className={clsx(classes.fingers)}
                                        >
                                            <FormControl>
                                                <RadioGroup
                                                    className={
                                                        classes.radioGroup
                                                    }
                                                    aria-label="fingers"
                                                    name="fingers"
                                                    value={value}
                                                    onChange={handleChange}
                                                >
                                                    {Array.from(
                                                        { length: 5 },
                                                        (_, index) => (
                                                            <FormControlLabel
                                                                key={index + 1}
                                                                value={(
                                                                    index + 1
                                                                ).toString()}
                                                                disabled={
                                                                    disabled[
                                                                        index +
                                                                            1
                                                                    ]
                                                                }
                                                                checked={
                                                                    value ===
                                                                    (
                                                                        index +
                                                                        1
                                                                    ).toString()
                                                                }
                                                                color={'error'}
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={(
                                                                    index + 1
                                                                ).toString()}
                                                                labelPlacement="top"
                                                            />
                                                        )
                                                    )}
                                                </RadioGroup>
                                            </FormControl>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    className={
                                                        classes.radioGroupLeft
                                                    }
                                                    aria-label="fingers"
                                                    name="fingers"
                                                    value={value}
                                                    onChange={handleChange}
                                                >
                                                    {Array.from(
                                                        { length: 5 },
                                                        (_, index) => (
                                                            <FormControlLabel
                                                                key={index + 6}
                                                                value={(
                                                                    index + 6
                                                                ).toString()}
                                                                disabled={
                                                                    disabled[
                                                                        index +
                                                                            6
                                                                    ]
                                                                }
                                                                checked={
                                                                    value ===
                                                                    (
                                                                        index +
                                                                        6
                                                                    ).toString()
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={(
                                                                    index + 6
                                                                ).toString()}
                                                                labelPlacement="top"
                                                            />
                                                        )
                                                    )}
                                                </RadioGroup>
                                            </FormControl>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={!value}
                                            >
                                                Сканирай
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </form>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                {/* <AddUserForm /> */}
                                <ValidationGroup>
                                    <Card className={classes.card}>
                                        {allocateMessage}
                                        {allocateErrorMessage}
                                        <form
                                            onSubmit={handleSubmit(
                                                allocateUser
                                            )}
                                            name="form"
                                        >
                                            <CardHeader
                                                titleTypographyProps={{
                                                    variant: 'h6',
                                                }}
                                                title="Изпращане на служител към устройство"
                                            />
                                            <CardContent
                                                style={{
                                                    padding: '8px !important',
                                                }}
                                                className={classes.cardContent}
                                            >
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Validate
                                                            name="user"
                                                            required
                                                        >
                                                            <Autocomplete
                                                                value={
                                                                    users.sap
                                                                }
                                                                onChange={(
                                                                    event,
                                                                    newUserValue
                                                                ) => {
                                                                    setUserValue(
                                                                        newUserValue
                                                                    );
                                                                }}
                                                                inputValue={
                                                                    userInputValue
                                                                }
                                                                onInputChange={(
                                                                    event,
                                                                    userInputValue
                                                                ) => {
                                                                    setUserInputValue(
                                                                        userInputValue
                                                                    );
                                                                }}
                                                                id="auto-select user"
                                                                getOptionLabel={option =>
                                                                    option.name +
                                                                        ' - ' +
                                                                        option.sap ||
                                                                    ''
                                                                }
                                                                className={
                                                                    classes.textField
                                                                }
                                                                options={users}
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Служител"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        style={{
                                                                            marginLeft:
                                                                                '0 !important',
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Validate>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Validate
                                                            name="device"
                                                            required
                                                        >
                                                            <Autocomplete
                                                                autoSelect
                                                                value={
                                                                    devices.mac
                                                                }
                                                                onChange={(
                                                                    event,
                                                                    newDeviceValue
                                                                ) => {
                                                                    setDeviceValue(
                                                                        newDeviceValue
                                                                    );
                                                                }}
                                                                inputValue={
                                                                    deviceInputValue
                                                                }
                                                                onInputChange={(
                                                                    event,
                                                                    deviceInputValue
                                                                ) => {
                                                                    setDeviceInputValue(
                                                                        deviceInputValue
                                                                    );
                                                                }}
                                                                id="auto-select device"
                                                                getOptionLabel={devices =>
                                                                    devices.name
                                                                }
                                                                getOptionSelected={devices =>
                                                                    devices.mac
                                                                }
                                                                className={
                                                                    classes.textField
                                                                }
                                                                options={
                                                                    devices
                                                                }
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Устройство"
                                                                        variant="outlined"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        </Validate>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            <CardActions>
                                                <AutoDisabler>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className={
                                                            classes.button
                                                        }
                                                    >
                                                        Изпрати
                                                    </Button>
                                                </AutoDisabler>
                                            </CardActions>
                                        </form>
                                    </Card>
                                </ValidationGroup>

                                <ValidationGroup>
                                    <form
                                        onSubmit={handleSubmit(deallocateUser)}
                                        name="form"
                                    >
                                        <Card
                                            className={classes.card}
                                            style={{ marginTop: '20px' }}
                                        >
                                            {deallocateMessage}
                                            {deallocateErrorMessage}
                                            <CardHeader
                                                titleTypographyProps={{
                                                    variant: 'h6',
                                                }}
                                                title="Премахване на служител от устройство"
                                            />
                                            <CardContent
                                                style={{
                                                    padding: '8px !important',
                                                }}
                                                className={classes.cardContent}
                                            >
                                                <Grid container>
                                                    <Grid item xs={12} lg={6}>
                                                        <Validate
                                                            name="user"
                                                            required
                                                        >
                                                            <Autocomplete
                                                                value={
                                                                    users.sap
                                                                }
                                                                onChange={(
                                                                    event,
                                                                    newUserValue
                                                                ) => {
                                                                    setUserDeallocateValue(
                                                                        newUserValue
                                                                    );
                                                                }}
                                                                inputValue={
                                                                    inputDeallocateValue
                                                                }
                                                                onInputChange={(
                                                                    event,
                                                                    userInputValue
                                                                ) => {
                                                                    setInputDeallocateValue(
                                                                        userInputValue
                                                                    );
                                                                }}
                                                                id="auto-select user"
                                                                getOptionLabel={option =>
                                                                    option.name +
                                                                        ' - ' +
                                                                        option.sap ||
                                                                    ''
                                                                }
                                                                className={
                                                                    classes.textField
                                                                }
                                                                options={users}
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Служител"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        style={{
                                                                            marginLeft:
                                                                                '0 !important',
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Validate>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <Validate
                                                            name="device"
                                                            required
                                                        >
                                                            <Autocomplete
                                                                autoSelect
                                                                value={
                                                                    devices.mac
                                                                }
                                                                onChange={(
                                                                    event,
                                                                    newDeviceValue
                                                                ) => {
                                                                    setDeviceDeallocateValue(
                                                                        newDeviceValue
                                                                    );
                                                                }}
                                                                inputValue={
                                                                    deviceDeallocateInputValue
                                                                }
                                                                onInputChange={(
                                                                    event,
                                                                    deviceInputValue
                                                                ) => {
                                                                    setDeviceInputDeallocateValue(
                                                                        deviceInputValue
                                                                    );
                                                                }}
                                                                id="auto-select device"
                                                                getOptionLabel={devices =>
                                                                    devices.name.toString()
                                                                }
                                                                getOptionSelected={devices =>
                                                                    devices.mac
                                                                }
                                                                className={
                                                                    classes.textField
                                                                }
                                                                options={
                                                                    devices
                                                                }
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Устройство"
                                                                        variant="outlined"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        </Validate>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            <CardActions>
                                                <AutoDisabler>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className={
                                                            classes.button
                                                        }
                                                    >
                                                        Премахни
                                                    </Button>
                                                </AutoDisabler>
                                            </CardActions>
                                        </Card>
                                    </form>
                                </ValidationGroup>

                                <ValidationGroup>
                                    <Card
                                        className={classes.card}
                                        style={{ marginTop: '20px' }}
                                    >
                                        {allMessage}
                                        <CardContent
                                            style={{
                                                padding: '8px !important',
                                            }}
                                            className={classes.cardContent}
                                        >
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Validate
                                                        name="user"
                                                        required
                                                    >
                                                        <Autocomplete
                                                            value={users.sap}
                                                            onChange={(
                                                                event,
                                                                newUserValue
                                                            ) => {
                                                                setUserValue(
                                                                    newUserValue
                                                                );
                                                            }}
                                                            inputValue={
                                                                userInputValue
                                                            }
                                                            onInputChange={(
                                                                event,
                                                                userInputValue
                                                            ) => {
                                                                setUserInputValue(
                                                                    userInputValue
                                                                );
                                                            }}
                                                            id="auto-select user"
                                                            getOptionLabel={users =>
                                                                users.name
                                                            }
                                                            className={
                                                                classes.textField
                                                            }
                                                            options={users}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Служител"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    style={{
                                                                        marginLeft:
                                                                            '0 !important',
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Validate>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <CardActions>
                                            <AutoDisabler>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    onClick={sendToAll}
                                                >
                                                    изпращане към всички
                                                </Button>
                                            </AutoDisabler>
                                            <AutoDisabler>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="bad"
                                                    className={classes.button}
                                                    onClick={
                                                        removeFromEverywhere
                                                    }
                                                >
                                                    изтриване от всички
                                                </Button>
                                            </AutoDisabler>
                                        </CardActions>
                                    </Card>
                                </ValidationGroup>
                            </Grid>
                        </Grid>
                        <Dialog open={openModal}>
                            <DialogContent dividers>
                                <Grid
                                    container
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Typography
                                        variant="h5"
                                        gutterBottom
                                        align="center"
                                    >
                                        Поставете и повдигнете пръста си 3 пъти
                                        подред за 1 секунда
                                    </Typography>
                                    <img
                                        src={EnrollGif}
                                        width="200"
                                        alt="enrolling..."
                                    />
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Container>
                </Scrollbar>
            </main>
        </div>
    );
}
