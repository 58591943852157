import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableFilterRow, MTableToolbar } from 'material-table';
import LogsDataService from '../../services/LogService';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime, Settings } from 'luxon';
import dayjs from 'dayjs';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/bg';

Settings.defaultZone = 'Asia/Istanbul';

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    grid: { marginLeft: theme.spacing(2) },
    toolbar: { marginLeft: theme.spacing(10) },
    absoluteContainer: {
        position: 'absolute',
        zIndex: 9,
        width: '45vw',
        [theme.breakpoints.down('md')]: {
            position: 'initial',
        },
    },
}));

const EventsTable = () => {
    const classes = useStyles();
    const [events, setLog] = useState([]);

    // Get user role from localStorage as a simple string
    const [isAnalyzer, setIsAnalyzer] = useState(false);

    // Initialize user role from localStorage when component mounts
    useEffect(() => {
        const userRole = localStorage.getItem('level');
        // Check if the role is 'analyzer'
        if (userRole) {
            setIsAnalyzer(userRole === 'analyzer');
        }
    }, []);

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    const tableRef = React.createRef();

    useEffect(() => {
        retrieveEvents();
    }, []);

    const retrieveEvents = () => {
        LogsDataService.getAll()
            .then(response => {
                setLog(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    JSON.stringify(events, function(key, room) {
        // if room is null, return "" as a replacement
        if (room === null) {
            return 'empty';
        }

        // otherwise, leave the room unchanged
        return room;
    });

    const retrieveFilteredEvents = resolve => {
        LogsDataService.getAllDateFilter(
            JSON.stringify(selectedStartDate)
                .split('T')[0]
                .replace(/\"/g, ''),
            JSON.stringify(selectedEndDate)
                .split('T')[0]
                .replace(/\"/g, '')
        )
            .then(response => {
                setLog(response.data);
                console.log(response.data);
                tableRef.current.onQueryChange();
                resolve();
            })
            .catch(e => {
                console.log(e);
            });
    };

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const handleEventRemove = (Event, resolve) => {
        LogsDataService.remove(Event.id)
            .then(res => {
                const dataRemove = [...events];
                const index = Event.tableData.id;
                dataRemove.splice(index, 1);
                setLog([...dataRemove]);
                resolve();
            })
            .catch(error => {
                setErrorMessages(['Remove failed! Server error']);
                setIserror(true);
            });
    };

    //! Edit User Event Start
    const [submitted, setSubmitted] = useState(false);

    const handleEventUpdate = (oldEvent, resolve) => {
        LogsDataService.update(oldEvent.id, oldEvent)
            .then(response => {
                setSubmitted(true);
                console.log(response.data);
                resolve();
            })
            .catch(e => {
                console.log(e);
            });
    };

    const updateEvent = () => {
        setSubmitted(false);
    };
    //! Edit User Event Stop

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    // var loc_name = events.reduce(function (acc, cur, i) {
    //     acc[cur.id] = cur.userEvent.name;

    //     return acc;
    // }, {});

    // var sub_loc_name = departments.reduce(function (acc, sub_loc, i) {
    //     acc[sub_loc.id] = sub_loc.name;

    //     return acc;
    // }, {});

    const SPACED_DATE_FORMAT = 'DD/MMM/YYYY';
    const SPACED_TIME_FORMAT = ' hh:mm';

    // * Add Filters to State Start
    const [selectedRows, setSelectedRows] = useState([]);

    // * Date Hooks
    var startDate = DateTime.local().startOf('day');

    // startDate.setHours(3, 0, 0, 0)

    const [selectedStartDate, handleStartDateChange] = useState(null);

    var endDate = DateTime.local().endOf('day');
    const [selectedEndDate, handleEndDateChange] = useState(null);

    const [components] = useState({
        Toolbar: props => (
            <div>
                <MTableToolbar {...props} />
            </div>
        ),
        FilterRow: props => <MTableFilterRow {...props} />,
    });

    const [columns] = useState([
        {
            title: 'Дата',
            field: 'time',
            type: 'date',
            dateSetting: { locale: 'bg-BG', hour12: false },
            filtering: false,
        },
        {
            title: 'Час',
            field: 'time',
            type: 'time',
            dateSetting: { locale: 'bg-BG', hour12: false },
            filtering: false,
        },
        {
            title: 'Topic',
            field: 'topic',
        },
        { title: 'Съобщение', field: 'message', editable: 'never' },
        {
            title: 'Локация',
            field: 'room',
            editable: 'never',
            cellStyle: (e, rowData) => {
                if (rowData.room === 'empty') {
                    return { backgroundColor: '#FFCCCC' };
                }
            },
        },
    ]);
    // * Add Filters to State End

    return (
        <div>
            <Grid
                container
                spacing={1}
                className={classes.absoluteContainer}
                style={{ paddingLeft: '16px' }}
            >
                <Grid item xs={4} lg={3} style={{ paddingTop: '20px' }}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="bg"
                    >
                        <MobileDatePicker
                            margin="normal"
                            closeOnSelect={true}
                            id="selectedStartDate"
                            label="Начална Дата"
                            defaultValue={dayjs()}
                            ampm={false}
                            value={selectedStartDate}
                            onChange={date => handleStartDateChange(date)}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4} lg={3} style={{ paddingTop: '20px' }}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="bg"
                    >
                        <MobileDatePicker
                            margin="normal"
                            closeOnSelect={true}
                            id="selectedEndDate"
                            label="Крайна Дата"
                            defaultValue={dayjs()}
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4} lg={6}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={retrieveFilteredEvents}
                    >
                        Филтрирай
                    </Button>
                </Grid>
            </Grid>
            <MaterialTable
                title="Събития"
                columns={columns}
                data={events}
                style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: retrieveEvents,
                    },
                ]}
                options={{
                    loadingType: 'linear',
                    pageSize: 50,
                    maxBodyHeight: '68vh',
                    showTitle: false,
                    sorting: true,
                    actionsColumnIndex: -1,
                    exportButton: !isAnalyzer
                        ? { csv: true, pdf: false }
                        : false,
                    filtering: false,
                    pageSizeOptions: [20, 50, 100, 200, 300, 400, 500],
                    search: true,
                    toolbar: true,
                }}
                components={components}
                // editable={{
                // 	onRowUpdate: oldEvent =>
                // 		new Promise(resolve => {
                // 			handleEventUpdate(oldEvent, resolve)
                // 			retrieveEvents()
                // 		}),
                // 	onRowDelete: Event =>
                // 		new Promise(resolve => {
                // 			handleEventRemove(Event, resolve)
                // 			retrieveEvents()
                // 		}),
                // }}
                onSelectionChange={rows => {
                    setSelectedRows(rows);
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} от {count}',
                        labelRowsSelect: 'Събития',
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Зареждаме събитията...',
                        editTooltip: 'Редактирай',
                        deleteTooltip: 'Премахни',
                        filterRow: {
                            filterTooltip: 'Филтър',
                        },
                        editRow: {
                            deleteText:
                                'Сигурни ли сте че искате да изтриете събитието?',
                            cancelTooltip: 'Отказ',
                            saveTooltip: 'Запази',
                        },
                    },
                    toolbar: {
                        exportTitle: 'Експорт',
                        exportName: 'Експорт към CSV',
                        searchTooltip: 'Tърси',
                        searchPlaceholder: 'Tърси',
                        nRowsSelected: '{0} реда избрани',
                    },
                }}
            />
        </div>
    );
};

export default EventsTable;
