import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableFilterRow } from 'material-table';
import ChangesDataService from '../../../services/ChangesService';
import DescriptionIcon from '@material-ui/icons/Description';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';

const EventsTable = () => {
    const [changes, setChanges] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    useEffect(() => {
        retrieveChanges();
    }, []);

    const retrieveChanges = () => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            ChangesDataService.getAll(loc)
                .then(response => {
                    setChanges(response.data);
                    setIsLoading(false);
                    resolve();
                })
                .catch(e => {
                    console.log(e);
                    setIsLoading(false);
                    reject(e);
                });
        });
    };

    // * Add Filters to State Start
    const [selectedRows, setSelectedRows] = useState([]);

    const [components] = useState({
        FilterRow: props => <MTableFilterRow {...props} />,
    });

    const formatChanges = (changes, previous_data, rowData) => {
        let formattedInfo = '';
        let style = {};

        // First, determine if this is a new record, edit, or deletion
        if (changes && changes.action === 'DELETE') {
            formattedInfo = `ИЗТРИТ ЗАПИС\n`;
            formattedInfo += `─────────────────────\n`;
            formattedInfo += `Събитие: ${changes.event}\n`;
            formattedInfo += `Дата и час на събитие: \n${new Date(
                changes.time
            ).toLocaleString('bg-BG')}\n`;
            formattedInfo += `Локация: ${
                rowData.Location ? rowData.Location.name : changes.location
            }\n`;
            formattedInfo += `─────────────────────\n`;
            style = {
                backgroundColor: '#ffebee',
                color: '#c62828',
                padding: '8px',
                borderRadius: '4px',
                margin: '4px 0',
            };
        } else if (!previous_data) {
            formattedInfo = `НОВ ЗАПИС\n`;
            formattedInfo += `─────────────────────\n`;
            if (changes.time) {
                formattedInfo += `Дата и час на събитие: \n${new Date(
                    changes.time
                ).toLocaleString('bg-BG')}\n`;
            }
            if (changes.event) {
                formattedInfo += `Събитие: ${changes.event}\n`;
            }
            if (changes.location) {
                formattedInfo += `Локация: ${
                    rowData.Location ? rowData.Location.name : changes.location
                }\n`;
            }
            formattedInfo += `─────────────────────\n`;
            style = {
                backgroundColor: '#e8f5e9',
                color: '#2e7d32',
                padding: '8px',
                borderRadius: '4px',
                margin: '4px 0',
            };
        } else {
            formattedInfo = `РЕДАКТИРАН ЗАПИС\n`;
            formattedInfo += `─────────────────────\n`;

            if (changes.time) {
                const oldDate = new Date(previous_data.time);
                const newDate = new Date(changes.time);

                // Check if only time was changed (same date)
                if (oldDate.toDateString() === newDate.toDateString()) {
                    formattedInfo += `Променено време: \n${oldDate.toLocaleTimeString(
                        'bg-BG'
                    )} ➔ ${newDate.toLocaleTimeString('bg-BG')}\n`;
                }
                // Check if only date was changed (same time)
                else if (oldDate.toTimeString() === newDate.toTimeString()) {
                    formattedInfo += `Променена дата: \n${oldDate.toLocaleDateString(
                        'bg-BG'
                    )} ➔ ${newDate.toLocaleDateString('bg-BG')}\n`;
                }
                // Both date and time were changed
                else {
                    formattedInfo += `Променени дата и час:\n`;
                    formattedInfo += `${oldDate.toLocaleString(
                        'bg-BG'
                    )} ➔ ${newDate.toLocaleString('bg-BG')}\n`;
                }
            }

            if (changes.event) {
                formattedInfo += `Променено събитие: ${previous_data.event ||
                    'празно'} ➔ ${changes.event}\n`;
            }

            if (changes.location) {
                formattedInfo += `Локация: ${
                    rowData.Location ? rowData.Location.name : changes.location
                }\n`;
            }

            formattedInfo += `─────────────────────\n`;

            style = {
                backgroundColor: '#fff3e0',
                color: '#ef6c00',
                padding: '8px',
                borderRadius: '4px',
                margin: '4px 0',
            };
        }

        return { formattedInfo, style };
    };

    const columns = [
        // {
        //     title: 'ID',
        //     field: 'id',
        //     width: 100,
        // },
        {
            title: 'Администратор',
            field: 'Administrator.name',
            width: 150,
        },
        {
            title: 'Промени',
            render: rowData => {
                const user_id = rowData.changes && rowData.changes.user_id;
                const { formattedInfo, style } = formatChanges(
                    rowData.changes,
                    rowData.previous_data,
                    rowData
                );

                return (
                    <div style={{ whiteSpace: 'pre-line' }}>
                        <strong>
                            {rowData.model_name} #{rowData.record_id}
                        </strong>
                        <div style={style}>
                            {formattedInfo}
                            {user_id && (
                                <>
                                    <span>
                                        Служител:{' '}
                                        {rowData.User
                                            ? rowData.User.name
                                            : user_id}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Дата',
            field: 'createdAt',
            render: rowData =>
                new Date(rowData.createdAt).toLocaleString('bg-BG'),
            defaultSort: 'desc',
        },
    ];

    // ! Export Table
    const tableRef = React.createRef();

    const exportTable = () => {
        const workbook = XLSX.utils.book_new(); // Create a new workbook
        const transformedData = changes.map(rowData => {
            // Replicate the formatting logic for new information
            let formattedNewInfo = '';
            const { event, user_id, location, Administrator } = rowData.changes;
            const timeValue = rowData.changes && rowData.changes.time;

            // Formatting logic for new information
            if (timeValue && timeValue.trim() !== '') {
                const date = new Date(timeValue);
                const dateOptions = {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                };
                const timeOptions = {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                };

                const formattedDate =
                    new Intl.DateTimeFormat('bg-BG', dateOptions).format(date) +
                    ', ' +
                    new Intl.DateTimeFormat('bg-BG', timeOptions).format(date);

                formattedNewInfo += `Дата и час:\r\n ${formattedDate}\r\n`;
                if (event)
                    formattedNewInfo += `─────────────────────\r\nВид събитие: ${event}\r\n`;
                if (user_id)
                    formattedNewInfo += `─────────────────────\r\nСлужител: ${user_id}`;
                // Add other fields like 'location' if needed
            } else {
                formattedNewInfo = 'Това е ново събитие.';
            }

            // Replicate the formatting logic for old information
            let formattedOldInfo = '';
            const previousTimeValue =
                rowData.previous_data && rowData.previous_data.time;
            if (previousTimeValue && previousTimeValue.trim() !== '') {
                const date = new Date(previousTimeValue);
                const formattedDate =
                    new Intl.DateTimeFormat('bg-BG', {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                    }).format(date) +
                    ', ' +
                    new Intl.DateTimeFormat('bg-BG', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    }).format(date);

                formattedOldInfo = formattedDate;
            } else {
                formattedOldInfo = 'Това е ново събитие.';
            }

            // Formatting logic for createdAt
            const createdAtDate = new Date(rowData.createdAt);
            const formattedCreatedAt =
                new Intl.DateTimeFormat('bg-BG', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                }).format(createdAtDate) +
                ', ' +
                new Intl.DateTimeFormat('bg-BG', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                }).format(createdAtDate);

            const manualAdded = rowData.previous_data ? 'Не' : 'Да';
            const corrected = rowData.previous_data ? 'Да' : 'Не';
            const eventType =
                rowData.previous_data && rowData.previous_data.event;

            return {
                Администратор: rowData.Administrator.name,
                'Нова информация': formattedNewInfo,
                'Стара информация': formattedOldInfo, // Updated to use formattedOldInfo
                'Вид Събите': eventType,
                'Ръчно добавено': manualAdded,
                Коригирано: corrected,
                'Дата на създаване на промяна': formattedCreatedAt,
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(transformedData);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Changes');
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        const data = new Blob([excelBuffer], {
            type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(data, 'Промени.xlsx');
    };

    return (
        <div>
            <MaterialTable
                title="История на промените"
                columns={columns}
                data={changes}
                isLoading={isLoading}
                options={{
                    loadingType: 'linear',
                    pageSize: 50,
                    maxBodyHeight: '68vh',
                    showTitle: true,
                    sorting: true,
                    actionsColumnIndex: -1,
                    filtering: true,
                    pageSizeOptions: [50, 100, 200, 300, 400, 500],
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'Зареждане на данни...',
                        deleteTooltip: 'Изтрий',
                        editTooltip: 'Редактирай',
                        filterRow: {
                            filterTooltip: 'Филтър',
                        },
                    },
                    pagination: {
                        labelDisplayedRows: '{from}-{to} от {count}',
                        labelRowsSelect: 'реда',
                    },
                    toolbar: {
                        searchTooltip: 'Търсене',
                        searchPlaceholder: 'Търсене',
                    },
                    header: {
                        actions: 'Действия',
                    },
                }}
            />
        </div>
    );
};

export default EventsTable;
