import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import UserDataService from '../../../services/UserService';
import Button from '@material-ui/core/Button';
import RestoreIcon from '@material-ui/icons/Restore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    recoverButton: {
        marginRight: theme.spacing(1),
    },
}));

const UsersTable = () => {
    const classes = useStyles();
    const [users, setUser] = useState([]);
    const [usersPin, setUserPin] = useState([]);

    // Get user role from localStorage as a simple string
    const [isAnalyzer, setIsAnalyzer] = useState(false);

    // Initialize user role from localStorage when component mounts
    useEffect(() => {
        const userRole = localStorage.getItem('level');
        // Check if the role is 'analyzer'
        if (userRole) {
            setIsAnalyzer(userRole === 'analyzer');
        }
    }, []);

    useEffect(() => {
        retrieveUsers();
    }, []);

    const [loc] = React.useState(
        JSON.parse(localStorage.getItem('loc')).id || ''
    );

    const retrieveUsers = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await UserDataService.getAllFired(joinedValues);
            } else if (locData.id === 'all') {
                response = await UserDataService.adminAllFired();
            }

            if (response) {
                setUser(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Function to handle user recovery
    const handleRecoverUser = async userId => {
        try {
            // Find the user data from the users state
            const userToRecover = users.find(user => user.sap === userId);
            if (!userToRecover) {
                throw new Error('User not found');
            }

            // Prepare the update data - setting deletedAt to null
            const updateData = {
                deletedAt: null,
            };

            // Call the update service
            await UserDataService.update(userId, updateData);

            // Refresh the users list after successful recovery
            retrieveUsers();

            // Show success message
            alert('Потребителят е възстановен успешно');
        } catch (error) {
            console.error('Error recovering user:', error);
            setErrorMessages([
                'Възстановяването на потребителя не беше успешно',
            ]);
            setIserror(true);
        }
    };

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const FIRED_DATE_FORMAT = 'DD/MMM/YYYY';

    return (
        <div>
            <MaterialTable
                title="Изключени служители"
                columns={[
                    // { title: 'Id', field: 'id' },
                    { title: 'Име', field: 'name', defaultSort: 'asc' },
                    {
                        title: 'Локация',
                        field: 'loc_name',
                        editable: 'never',
                    },
                    {
                        title: 'Отдел',
                        field: 'sub_loc_name',
                        editable: 'never',
                    },
                    { title: 'No.', field: 'sap' },
                ]}
                data={users}
                style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: retrieveUsers,
                    },
                    // Add the recover action if the user is not an analyzer
                    ...(!isAnalyzer
                        ? [
                              {
                                  icon: () => <RestoreIcon />,
                                  tooltip: 'Възстанови потребител',
                                  onClick: (event, rowData) =>
                                      handleRecoverUser(rowData.sap),
                              },
                          ]
                        : []),
                ]}
                options={{
                    loadingType: 'linear',
                    pageSize: 50,
                    maxBodyHeight: '68vh',
                    showTitle: true,
                    sorting: true,
                    actionsColumnIndex: -1,
                    exportButton: !isAnalyzer
                        ? { csv: true, pdf: false }
                        : false,
                    filtering: true,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}',
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Няма изключени служители.',
                        filterRow: {
                            filterTooltip: 'Filter',
                        },
                        editRow: {
                            deleteText:
                                'Сигурни ли сте че искате да изтриете служителя?',
                        },
                    },
                }}
            />
        </div>
    );
};

export default UsersTable;
