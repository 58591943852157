import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableFilterRow } from 'material-table';
import EventDataService from '../../../services/EventService';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';
import DescriptionIcon from '@material-ui/icons/Description';

const WorkingTodayTable = () => {
    const [events, setEvent] = useState([]);
    const [count, setCount] = useState([]);

    // Get user role from localStorage as a simple string
    const [isAnalyzer, setIsAnalyzer] = useState(false);

    // Initialize user role from localStorage when component mounts
    useEffect(() => {
        const userRole = localStorage.getItem('level');
        // Check if the role is 'analyzer'
        if (userRole) {
            setIsAnalyzer(userRole === 'analyzer');
        }
    }, []);

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    useEffect(() => {
        retrieveEvents();
        retrieveWorkingTodayCount();
    }, []);

    const retrieveEvents = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await EventDataService.getAllToday(joinedValues);
            } else if (locData.id === 'all') {
                response = await EventDataService.adminAllToday();
            }

            if (response) {
                setEvent(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveWorkingTodayCount = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await EventDataService.countToday(joinedValues);
            } else if (locData.id === 'all') {
                response = await EventDataService.adminCountToday();
            }

            if (response) {
                setCount(response.data.count);
                // console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const SPACED_DATE_FORMAT = 'DD/MMM/YYYY H:MM:ss';

    // * Add Filters to State Start
    const [selectedRows, setSelectedRows] = useState([]);

    const [components] = useState({
        FilterRow: props => <MTableFilterRow {...props} />,
    });

    const [columns] = useState([
        {
            title: 'Име',
            field: 'name',
            editable: 'never',
            defaultSort: 'asc',
        },
        { title: '# на Служител', field: 'sap', editable: 'never' },
        {
            title: 'Локация на устройство',
            field: 'location',
            editable: 'never',
        },
        { title: 'Отдел', field: 'sub_loc_name', editable: 'never' },
        { title: 'Устройство', field: 'device_name', editable: 'never' },
    ]);
    // * Add Filters to State End

    // ! Format the Date and time columns for export
    const formatDate = inputTime => {
        if (inputTime && inputTime !== 'null' && inputTime !== 'empty') {
            const date = new Date(inputTime);
            return `${date
                .getDate()
                .toString()
                .padStart(2, '0')}.${(date.getMonth() + 1)
                .toString()
                .padStart(2, '0')}.${date.getFullYear()} г., ${date
                .getHours()
                .toString()
                .padStart(2, '0')}:${date
                .getMinutes()
                .toString()
                .padStart(2, '0')}:${date
                .getSeconds()
                .toString()
                .padStart(2, '0')} ч.`;
        }
        return 'empty';
    };

    // ! Header mapping for exported column titles
    const headerMapping = {};
    columns.forEach(col => {
        headerMapping[col.field] = col.title;
    });

    const mapHeaders = row => {
        let newRow = {};
        for (let key in row) {
            if (headerMapping[key]) {
                newRow[headerMapping[key]] = row[key];
            } else {
                newRow[key] = row[key];
            }
        }
        return newRow;
    };

    // ! Format the name for the file
    const [selectedStartDate, setStartDateChange] = useState(null);
    const [selectedEndDate, setEndDateChange] = useState(null);
    const formatDateForFilename = inputDate => {
        const date = new Date(inputDate);
        return `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date
            .getDate()
            .toString()
            .padStart(2, '0')}`;
    };

    const formattedStartDate = formatDateForFilename(selectedStartDate);
    const formattedEndDate = formatDateForFilename(selectedEndDate);

    // ! Export to XLS Function
    const tableRef = React.createRef();
    const exportTable = () => {
        const currentTableData = tableRef.current.dataManager.filteredData;

        // Transform the data
        const transformedData = currentTableData.map(row => {
            // Check for << and >> conditions for first_time and last_time
            const firstTimeValue =
                row.first_time === '<<'
                    ? 'липсва вход'
                    : formatDate(row.first_time);
            const lastTimeValue =
                row.last_time === '>>'
                    ? 'липсва изход'
                    : formatDate(row.last_time);

            return {
                ...row,
                time: formatDate(row.time),
                first_time: firstTimeValue,
                last_time: lastTimeValue,
            };
        });

        // Only include displayed columns in the export
        const exportedData = transformedData.map(row => {
            let newRow = {};
            columns.forEach(col => {
                newRow[col.field] = row[col.field];
            });
            return mapHeaders(newRow);
        });

        // Export the data
        const ws = XLSX.utils.json_to_sheet(exportedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const buf = XLSX.write(wb, { bookType: 'xls', type: 'array' });
        saveAs(
            new Blob([buf], { type: 'application/vnd.ms-excel' }),
            `Enter/Exit_${formattedStartDate} - ${formattedEndDate}.xls`
        );
    };

    return (
        <div>
            <MaterialTable
                title={`Служители на работа днес: ${count}`}
                columns={columns}
                data={events}
                tableRef={tableRef}
                style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: retrieveEvents,
                    },
                    ...(!isAnalyzer
                        ? [
                              {
                                  icon: () => <DescriptionIcon />,
                                  tooltip: 'Export to Excel',
                                  isFreeAction: true,
                                  onClick: exportTable,
                              },
                          ]
                        : []),
                ]}
                options={{
                    loadingType: 'linear',
                    maxBodyHeight: '67vh',
                    pageSize: 100,
                    showTitle: true,
                    sorting: true,
                    actionsColumnIndex: -1,
                    exportButton: !isAnalyzer
                        ? { csv: true, pdf: false }
                        : false,
                    filtering: true,
                    pageSizeOptions: [20, 50, 100, 200, 300, 400, 500],
                    toolbar: true,
                }}
                components={components}
                // editable={{
                // 	onRowUpdate: oldEvent =>
                // 		new Promise(resolve => {
                // 			handleEventUpdate(oldEvent, resolve)
                // 			retrieveEvents()
                // 		}),
                // 	onRowDelete: Event =>
                // 		new Promise(resolve => {
                // 			handleEventRemove(Event, resolve)
                // 			retrieveEvents()
                // 		}),
                // }}
                onSelectionChange={rows => {
                    setSelectedRows(rows);
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} от {count}',
                        labelRowsSelect: 'Събития',
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Зареждаме събитията...',
                        editTooltip: 'Редактирай',
                        deleteTooltip: 'Премахни',
                        filterRow: {
                            filterTooltip: 'Филтър',
                        },
                        editRow: {
                            deleteText:
                                'Сигурни ли сте че искате да изтриете събитието?',
                            cancelTooltip: 'Отказ',
                            saveTooltip: 'Запази',
                        },
                    },
                    toolbar: {
                        exportTitle: 'Експорт',
                        exportName: 'Експорт към CSV',
                        searchTooltip: 'Tърси',
                        searchPlaceholder: 'Tърси',
                        nRowsSelected: '{0} реда избрани',
                    },
                }}
            />
        </div>
    );
};

export default WorkingTodayTable;
